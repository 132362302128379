.mainBody{
    /* background-color: aqua; */
    padding: 120px 0px 20px 0px;
    overflow: visible; 
    min-width: 300px;
    max-width: 700px;
    /* ^^this prevents margin collapse in children */
    color: var(--dark-color-1)
    
}

/* @media screen and (max-width: 500px){
    .mainBody{
        padding-bottom: 7px;
    }
} */