.bodySection {
    /* background-color: darkseagreen; */
    padding: 15px 0px;
   
    /* border: 1px yellow solid; */
}

.separatorGraphic {
    max-width: 700px;
    width: 80%;
}

.sectionContent {
    max-width: 600px;
    width: 70%;
    margin: auto;
}

/* @media screen and (max-width: 500px){
    .bodySection{
        width: 100vw;
    }
} */