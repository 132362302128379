.topSection {
    /* background-color: rgb(224, 145, 240); */
    /* padding-top: 100px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.headerGraphic {
    width: 80%;
    max-width: 700px;
}

.headerPhoto {
    max-width: 600px;
    width: 70%;
    padding: 20px 0px 40px 0px;
}

/* @media screen and (max-width: 500px){
    .topSection{
        width: 100vw;
    }
} */

