.App {
  text-align: center;
  background-color: var(--light-color-1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* min-width: 300px; */
}

/* @media only screen and (max-width: 500px){
  .App{
    min-width: unset;
  }
} */


