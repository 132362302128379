:root {
  --dark-color-1: #1D1E35;
  --light-color-1: #faf5ea; 
  --hover-link-color: #4c4c50;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'OptimaMedium', 'OptimaB', 'Optima';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* width: 100vw; */
  min-width: 300px;
}

a {
  text-decoration: none;
  color: var(--dark-color-1);
}

a:hover{
  color: var(--hover-link-color);
}

address {
  font-style: unset;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Optima';
  src: local("Optima"), 
    url('./assets/fonts/optima-cufonfonts-webfont/OPTIMA.woff');
    font-weight: normal;
}

@font-face {
  font-family: 'OptimaMedium';
  src: local("OptimaMedium"), 
    url('./assets/fonts/optima-cufonfonts-webfont/Optima Medium.woff');
    font-weight: 500;
}

@font-face {
  font-family: 'OptimaB';
  src: local("OptimaB"), 
    url('./assets/fonts/optima-cufonfonts-webfont/OPTIMA_B.woff');
    font-weight: bold;
}

/* @media screen and (max-width: 500px){
  html{
      width: 100vw;
  }
} */