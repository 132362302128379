.footer {
    width: 100%;
    min-width: 300px;
    /* height: 75px; */
    background-color: var(--light-color-1);
    position: fixed;
    bottom: 0px;
    /* display: flex;
    align-items: center; */
}

.borderDivFooter{
    border-top: 1px var(--dark-color-1) solid;
    width: 80%;
    max-width: 1000px;
    margin: auto;
    /* background-color: yellow; */
}

.socialIcon {
    color: var(--dark-color-1);
    margin: 10px;
    /* background-color: tomato; */
}

@media screen and (max-width: 500px){
    .footer{
        height: 55px;
    }
    
}