nav {
    height: 100px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 300px;
    background-color: var(--light-color-1);
    z-index: 1;
}

.borderDivNav{
    height: 100%;
    width: 80%;
    max-width: 1000px;
    border-bottom: 1px var(--dark-color-1) solid;
    margin: auto;
    display: flex;
    /* background-color: pink; */
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 500px){
    nav{
        font-size: .65em;
        /* min-width: unset; */
    }
}